import {
  ButtonGroup,
  Floater,
  OnlyShow,
  TButtonGroupItem,
  useListState,
  useWindowScroll,
} from '@ingeniorforeningen/figurine-core';
import { useEffect, useState } from 'react';

const StickyProgramAndSignup = ({
  heroPresent = false,
  program,
  signup,
  isActive,
}: {
  heroPresent?: boolean;
  program?: Record<string, any>;
  signup?: Record<string, any>;
  isActive?: boolean;
}) => {
  if (!isActive) {
    return null;
  }

  const [{ y }] = useWindowScroll();
  const [isInHero, setIsInHero] = useState(heroPresent);
  const [buttons, handlers] = useListState<TButtonGroupItem>([]);

  const getUrl = (route: Record<string, any>) => route?.route?.path || route?.url || '';

  useEffect(() => {
    if (program) {
      handlers.setItem(0, {
        text: program.title as string,
        href: getUrl(program),
        icon: 'ListDetails',
      });
    }

    if (signup) {
      const index = program ? 1 : 0;
      handlers.setItem(index, {
        text: signup.title as string,
        href: getUrl(signup),
        icon: 'UserPlus',
      });
    }
  }, []);

  useEffect(() => {
    if (y <= 1) {
      setIsInHero(heroPresent);
    } else {
      setIsInHero(false);
    }
  }, [y]);

  return (
    <OnlyShow smallerThan="sm">
      <Floater
        sticky
        position={{
          bottom: 0,
        }}
        pb="xl"
        mb="xl"
      >
        <ButtonGroup
          variant={isInHero ? 'transparent' : 'cta'}
          style={{ width: '90vw' }}
          buttons={buttons}
          fullWidth
        />
      </Floater>
    </OnlyShow>
  );
};

export default StickyProgramAndSignup;
