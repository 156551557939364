import PageTop from '@/components/PageTop';
import SignUpCta from '@/components/SignUpCta';
import React from 'react';
import { LayoutProps } from '.';
import { useStyles } from '../styles';
import { Group, OnlyShow } from '@ingeniorforeningen/figurine-core';

const SignupPageLayout = ({ children, properties, image }: LayoutProps) => {
  const { classes } = useStyles();
  return (
    <>
      <PageTop image={image} properties={properties} />
      <section className={classes.main}>
        <OnlyShow largerThan="sm">
          <Group justify="center">
            <SignUpCta {...properties} />
          </Group>
        </OnlyShow>
        {children}
        <SignUpCta {...properties} />
      </section>
    </>
  );
};

export default SignupPageLayout;
