import StickyProgramAndSignup from '@/components/StickyProgramAndSignup';
import { LayoutProps } from '.';
import PageTop from '@/components/PageTop';
import { useStyles } from '../styles';

export const PersonInfoPageLayout = ({ children, properties, image }: LayoutProps) => {
  const { classes } = useStyles();
  return (
    <>
      <PageTop image={image} properties={properties} />
      <section className={classes.main}>{children}</section>
      <StickyProgramAndSignup
        isActive={properties?.isActive}
        program={properties?.programLink?.[0] as Record<string, any>}
        signup={properties?.signUpLink?.[0] as Record<string, any>}
      />
    </>
  );
};

export default PersonInfoPageLayout;
