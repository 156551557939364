import {
  Button,
  Floater,
  Group,
  ModalButton,
  OnlyShow,
  Paragraph,
  useLocaleText,
} from '@ingeniorforeningen/figurine-core';
import { useState } from 'react';

type TProps = {
  meetupNumberOnline?: number;
  meetupNumberPhysical?: number;
  multipleMeetupNumbersDescription?: string;
  multipleMeetupNumbersHeader?: string;
};

const createSignUpLink = (meetupNumber: number) =>
  `${process.env.NEXT_PUBLIC_IDA_DOMAIN}/arrangementer-og-kurser/tilmeldinger/${meetupNumber}/tilmeld`;

const SignUpCta = ({
  meetupNumberOnline,
  meetupNumberPhysical,
  multipleMeetupNumbersDescription,
  multipleMeetupNumbersHeader,
}: TProps) => {
  const [modalOpened, setModalOpened] = useState(false);
  const texts = useLocaleText();
  if (!meetupNumberOnline && !meetupNumberPhysical) {
    return null;
  }

  const renderCtaElement = (meetupNumber: number, fullWidth: boolean) => (
    <Button
      text={texts.signup}
      variant="cta"
      href={createSignUpLink(meetupNumber)}
      icon="ArrowRight"
      fullWidth={fullWidth}
    />
  );

  const isOnlineAndPhysical =
    typeof meetupNumberOnline === 'number' && typeof meetupNumberPhysical === 'number';

  const CtaButton = ({ fullWidth }: { fullWidth: boolean }) => {
    if (isOnlineAndPhysical) {
      return (
        <ModalButton
          buttonText={texts.signup}
          variant="cta"
          background="primary"
          modalContent={
            <>
              <Paragraph
                align="center"
                text={multipleMeetupNumbersDescription}
                p="md"
                variant="light"
              />
              <Group justify="center" align="center" py="md" direction="column">
                <Button
                  text={useLocaleText().online}
                  href={createSignUpLink(meetupNumberOnline)}
                  variant="outline"
                  contrast="dark"
                />
                <Button
                  text={useLocaleText().physical}
                  href={createSignUpLink(meetupNumberPhysical)}
                  variant="outline"
                  contrast="dark"
                />
              </Group>
            </>
          }
          withCloseButton
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          title={multipleMeetupNumbersHeader}
          icon="ArrowRight"
        />
      );
    }
    if (meetupNumberOnline) {
      return renderCtaElement(meetupNumberOnline, fullWidth);
    }
    return renderCtaElement(meetupNumberPhysical!, fullWidth);
  };
  return (
    <>
      <OnlyShow smallerThan="sm">
        <Floater sticky position={{ bottom: 0 }} py="xl" px="md" mt="xl">
          <CtaButton fullWidth />
        </Floater>
      </OnlyShow>
      <OnlyShow largerThan="sm">
        <Group justify="center" m="xl">
          <CtaButton fullWidth={false} />
        </Group>
      </OnlyShow>
    </>
  );
};

export default SignUpCta;
