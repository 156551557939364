import { TImageProps } from '@ingeniorforeningen/figurine-core';
import { IApiMediaWithCropsResponseModel } from '@ingeniorforeningen/umbraco-client';
import { useRouter } from 'next/router';
import { PageTop as PageTopComponent, TPageTopProps } from '@ingeniorforeningen/figurine-cms';
import React from 'react';
import { useStyles } from './styles';

type Props = {
  image: IApiMediaWithCropsResponseModel | undefined;
  properties: Record<string, unknown> | undefined;
  variant?: TPageTopProps['variant'];
};

const PageTop = ({ image, properties, variant }: Props) => {
  const { classes } = useStyles();

  if (image === undefined || properties === undefined) {
    return null;
  }

  const { locale } = useRouter();
  let pageTopImage: TImageProps | undefined = undefined;

  if (image && image.url !== undefined) {
    const focalpoint = image?.focalPoint
      ? (`${(image?.focalPoint?.left ?? 0) * 100}% ${
          (image?.focalPoint?.top ?? 0) * 100
        }%` as `${number}% ${number}%`)
      : undefined;

    pageTopImage = {
      polymorphicProps: { fill: true, priority: true },
      alt: locale === 'en' ? image.properties?.altText_en : image.properties?.altText_da,
      src: image.url,
      objectPosition: focalpoint,
    };
  }

  return (
    <PageTopComponent
      title={properties?.header as string}
      subtitle={properties?.title as string}
      teaser={properties?.teaser as string}
      image={pageTopImage}
      variant={variant || 'classic'}
      className={classes.root}
    />
  );
};

export default PageTop;

export const toImage = (
  image: IApiMediaWithCropsResponseModel | undefined,
  locale: string | undefined,
): TImageProps | undefined => {
  if (image && image.url !== undefined) {
    const pageTopImageSrc = 'https://' + process.env.IMAGE_DOMAIN + image.url;
    return {
      polymorphicProps: { fill: true, priority: true },
      alt: locale === 'en' ? image.properties?.altText_en : image.properties?.altText_da,
      src: pageTopImageSrc,
    } as TImageProps;
  }
  return undefined;
};
